import 'vite/modulepreload-polyfill'
import Alpine from 'alpinejs'
import WholesaleQuantity from '@scripts/components/wholesale-quantity'
import WholesaleCollection from '@scripts/sections/wholesale-collection'

window.Alpine = Alpine

document.addEventListener('alpine:init', () => {
  // store

  // components
  Alpine.data('wholesaleQuantity', WholesaleQuantity)

  // sections
  Alpine.data('wholesaleCollection', WholesaleCollection)
})

Alpine.start()

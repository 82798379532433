const WholesaleQuantity = ({ max = null, step, variant, handle, price }) => {
  return {
    quantity: 0,
    hasError: false,

    onQtyInput () {
      this.quantity = Number(this.quantity.replace(/[^0-9]/g, ''))

      if (max !== null && this.quantity > max) {
        this.quantity = max
      }

      if (this.quantity === 0) {
        this.hasError = false
      }

      this.$dispatch('qty-change', {
        variant, quantity: this.quantity, price
      })
    },

    onQtyStep (direction) {
      if (direction === 'minus') {
        const quantityResult = this.quantity - step

        this.quantity = quantityResult < 0 ? 0 : quantityResult
      } else {
        const quantityResult = this.quantity + step

        this.quantity = quantityResult > max ? max : quantityResult
      }

      this.$dispatch('qty-change', {
        variant, quantity: this.quantity, price
      })
    },

    handleCartSubmit (response) {
      if (!response.detail?.message && response.detail?.status === 422) {
        this.hasError = false
        return
      }

      // Find the product name in the cart message
      const productNameMatch = response.detail?.message.match(/add more (.*?) to the cart/i)

      if (!productNameMatch && !productNameMatch[1]) return
      const formattedProductName = productNameMatch[1].toLowerCase().replace(/\s+/g, '-')

      if (handle !== formattedProductName) return
      this.hasError = true
    }
  }
}

export default WholesaleQuantity
//

const WholesaleCollection = () => ({
  variants: [],
  showError: false,
  errorMessage: '',
  showSticky: false,
  subtotal: 0,
  isLoading: false,

  init () {
    this.cart = document.querySelector('cart-notification') || document.querySelector('cart-drawer')

    this.initStickyATC()
  },

  handleBackToTopClick () {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  },

  initStickyATC () {
    const tableFooter = this.$refs.tableFooter as HTMLElement

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.showSticky = false
          this.showError = false
        } else {
          this.showSticky = true

          if (this.errorMessage !== '') {
            this.showError = true
          }
        }
      })
    })

    // Observe the footer
    observer.observe(tableFooter)
  },

  handleQtyChange (data) {
    const { variant, quantity, price } = data
    const index = this.variants.findIndex(item => item.id === variant)

    if (index === -1 && quantity > 0) {
      // Variant does not exist and quantity is greater than 0, so add it
      this.variants.push({ id: variant, quantity, price })
    } else if (index !== -1) {
      if (quantity > 0) {
        // Variant exists, update its quantity
        this.variants[index].quantity = quantity
      } else {
        // Variant exists and quantity is 0, remove it
        this.variants.splice(index, 1)
      }
    }

    // Calculate the subtotal
    this.subtotal = '$' + this.variants.reduce((acc, item) => {
      return acc + ((item.price / 100) * item.quantity)
    }, 0).toFixed(2) // Ensure subtotal is always in 2 decimal places

    console.log(this.variants, 'variants')
    console.log(this.subtotal, 'subtotal')
  },

  handleSubmit () {
    if (this.variants.length === 0 || this.subtotal === 0) return

    this.isLoading = true

    const formData = {
      items: this.variants,
      ...this.cart && (
        {
          sections: this.cart.getSectionsToRender().map((section) => section.id),
          sections_url: window.location.pathname
        }
      )
    }

    if (this.cart) {
      this.cart.setActiveElement(document.activeElement)
    }

    fetch(window.Shopify.routes.root + 'cart/add.js', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
      },
      body: JSON.stringify(formData)
    })
      .then((response) => response.json())
      .then(response => {
        console.log(response)
        if (response.status) {
          publish(PUB_SUB_EVENTS.cartError, {
            source: 'product-form',
            errors: response.errors || response.description,
            message: response.message
          })

          this.showError = true
          this.errorMessage = response.message
          this.$dispatch('cart-submit', response)
          return
        } else if (!this.cart) {
          window.location = window.routes.cart_url
          return
        }

        if (!this.showError) {
          publish(PUB_SUB_EVENTS.cartUpdate, {
            source: 'product-form',
            cartData: response
          })
        }
        this.showError = false
        this.errorMessage = ''
        this.$dispatch('cart-submit', response)

        setTimeout(() => {
          this.cart.renderContents(response)
        })
      }).catch(error => {
        throw new Error('Error:', error)
      }).finally(() => {
        this.isLoading = false

        if (this.cart && this.cart.classList.contains('is-empty')) this.cart.classList.remove('is-empty')
      })
  }
})

export default WholesaleCollection
